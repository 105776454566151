<template>
  <div class="content-body pb-8">
    <div class="space-y-7">
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold">Leave Type</h1>
        <Button buttonText="Add Leave" @action="toLeaveTypeForm()" />
      </div>
      <div class="shadow-small card pt-4 pb-8 bg-white rounded-lg px-6 flex justify-between">
        <div class="flex-1 flex justify-start items-end">
          <div class="flex gap-2 items-end">
            <div class="w-96">
              <label class="text-neutral-500 text-xs font-medium mb-1 pl-3">Search Leave</label>
              <div class="relative">
                <div class="absolute left-4 top-1/2 transform -translate-y-1/2">
                  <Search color="neutral-400" />
                </div>
                <TextField type="text" withIcon borderEnabled placeholder="Search by leave name" :enterKeyAction="doSearch" v-model="keyword" />
              </div>
            </div>
            <div class="flex gap-5">
              <Button buttonText="Search" type="secondary" size="regular" additionalClass="px-6 py-3" @action="doSearch()" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="stillLoading" class="loading-page">
        <vue-simple-spinner size="large" message="Fetching Data ..."></vue-simple-spinner>
      </div>
      <div v-if="leaveTypeList.length > 0 && !stillLoading">
        <LeaveTable :columns="columnsTable" :list="leaveTypeList" compact>
          <template slot="table-row" slot-scope="{ entry, column }">
            <div v-if="column.label === 'Actions'" class="flex justify-end gap-3">
              <Button
                type="tertiary"
                size="icon"
                :forIcon="{ color: 'yellow' }"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Pencil')"
                :tooltip="{ show: true, text: 'Edit' }"
                @action="toLeaveTypeForm(entry.id)"
              />
              <Button
                type="delete"
                size="icon"
                :icon="() => import(/* webpackChunkName: 'icon' */ '@/components/Icons/Trash')"
                :tooltip="{ show: true, text: 'Delete' }"
                @action="showConfirmDelete(entry)"
              />
            </div>
            <div v-else-if="column.label === 'Leave Limit'">{{ entry[column.field] === parseInt(0) ? 'Unlimited' : entry[column.field] }}</div>
            <div v-else>{{ entry[column.field] }}</div>
          </template>
        </LeaveTable>
        <div v-if="totalLeaveType > sizePerPage" class="mt-3 flex justify-center items-center">
          <Pagination :currentPage="currentPage" :totalCount="totalLeaveType" :pageSize="sizePerPage" @onPageChange="onPageChange" />
        </div>
      </div>
      <div class="text-center mt-10" v-else>
        <img src="@/assets/images/empty-content.svg" alt="empty" class="mx-auto" />
        <div class="font-medium text-xl py-5">Data is not available</div>
      </div>
    </div>
    <Modal :modalVisible="confirmDeleteAlertVisible" @close="toggleConfirmDelete()" width="600px">
      <template slot="modal-content">
        <div class="text-center my-2">
          <h1 class="text-xl font-bold mb-3">Pemanently delete this Leave Type?</h1>
          <p class="text-sm mb-4">This action will delete {{ leaveTypeDeleted?.name }} Leave Type from Leave Management. This cannot be undone.</p>
          <div class="flex justify-center items-center gap-8">
            <Button buttonText="Delete" additionalClass="px-20" type="secondary" @action="deleteLeaveType()" />
            <Button buttonText="Cancel" additionalClass="px-20" type="primary" @action="toggleConfirmDelete()" />
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showVueToast } from '@/utils'
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Search: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Search'),
    LeaveTable: () => import(/* webpackChunkName: "simple-table" */ '@/components/Table/SimpleTable'),
    Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
  },
  data() {
    return {
      currentPage: 1,
      sizePerPage: 10,
      confirmDeleteAlertVisible: false,
      stillLoading: false,
      keyword: '',
      debounce: null,
      delayTime: 500,
      leaveTypeDeleted: null
    }
  },
  computed: {
    ...mapGetters('humanresource', ['leaveTypeList', 'totalLeaveType']),
    customerId() {
      return localStorage.getItem('client')
    },
    columnsTable() {
      return [
        { label: 'Leave Name', field: 'name' },
        { label: 'Duration Unit', field: 'unit' },
        { label: 'Leave Limit', field: 'maxDuration' },
        { label: 'Actions', field: 'btn', position: 'right' }
      ]
    }
  },
  created() {
    this.fetchingData()
  },
  methods: {
    ...mapActions('humanresource', ['GET_LEAVE_TYPE_LIST', 'DELETE_LEAVE_TYPE']),
    fetchingData() {
      this.stillLoading = true
      const params = {
        q: this.keyword,
        customerId: this.customerId,
        direction: 'DESC',
        limit: this.sizePerPage,
        page: this.currentPage - 1,
        sortBy: 'updatedDate'
      }

      this.GET_LEAVE_TYPE_LIST({ params }).then(() => {
        this.stillLoading = false
      })
    },
    onPageChange(page) {
      this.currentPage = page
      this.fetchingData()
    },
    deleteLeaveType() {
      this.stillLoading = true
      this.DELETE_LEAVE_TYPE({ leaveTypeId: this.leaveTypeDeleted.id }).then((res) => {
        if (res?.data?.code) {
          setTimeout(() => {
            showVueToast(`${this.leaveTypeDeleted.name} deleted successfully!`, 'success', 3000)
          }, 300)
        } else {
          setTimeout(() => {
            showVueToast(`Failed to delete ${this.leaveTypeDeleted.name}!`, 'error', 3000)
          }, 300)
        }
        this.toggleConfirmDelete()
        this.fetchingData()
      })
    },
    toLeaveTypeForm(leaveTypeId) {
      leaveTypeId ? this.$router.push(`/leave-type/update/${leaveTypeId}`) : this.$router.push('/leave-type/create')
    },
    doSearch() {
      this.currentPage = 1
      if (this.stillLoading) return
      this.fetchingData()
    },
    showConfirmDelete(leaveType) {
      this.leaveTypeDeleted = leaveType
      this.toggleConfirmDelete()
    },
    toggleConfirmDelete() {
      this.confirmDeleteAlertVisible = !this.confirmDeleteAlertVisible
    }
  }
}
</script>

<style lang="scss" scoped>
.content-body {
  min-height: calc(83vh - 0px);
}
</style>