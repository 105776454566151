var render = function () {
  var _vm$leaveTypeDeleted;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-body pb-8"
  }, [_c('div', {
    staticClass: "space-y-7"
  }, [_c('div', {
    staticClass: "flex justify-between items-center mb-6"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v("Leave Type")]), _c('Button', {
    attrs: {
      "buttonText": "Add Leave"
    },
    on: {
      "action": function action($event) {
        return _vm.toLeaveTypeForm();
      }
    }
  })], 1), _c('div', {
    staticClass: "shadow-small card pt-4 pb-8 bg-white rounded-lg px-6 flex justify-between"
  }, [_c('div', {
    staticClass: "flex-1 flex justify-start items-end"
  }, [_c('div', {
    staticClass: "flex gap-2 items-end"
  }, [_c('div', {
    staticClass: "w-96"
  }, [_c('label', {
    staticClass: "text-neutral-500 text-xs font-medium mb-1 pl-3"
  }, [_vm._v("Search Leave")]), _c('div', {
    staticClass: "relative"
  }, [_c('div', {
    staticClass: "absolute left-4 top-1/2 transform -translate-y-1/2"
  }, [_c('Search', {
    attrs: {
      "color": "neutral-400"
    }
  })], 1), _c('TextField', {
    attrs: {
      "type": "text",
      "withIcon": "",
      "borderEnabled": "",
      "placeholder": "Search by leave name",
      "enterKeyAction": _vm.doSearch
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1)]), _c('div', {
    staticClass: "flex gap-5"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Search",
      "type": "secondary",
      "size": "regular",
      "additionalClass": "px-6 py-3"
    },
    on: {
      "action": function action($event) {
        return _vm.doSearch();
      }
    }
  })], 1)])])]), _vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data ..."
    }
  })], 1) : _vm._e(), _vm.leaveTypeList.length > 0 && !_vm.stillLoading ? _c('div', [_c('LeaveTable', {
    attrs: {
      "columns": _vm.columnsTable,
      "list": _vm.leaveTypeList,
      "compact": ""
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(_ref) {
        var entry = _ref.entry,
            column = _ref.column;
        return [column.label === 'Actions' ? _c('div', {
          staticClass: "flex justify-end gap-3"
        }, [_c('Button', {
          attrs: {
            "type": "tertiary",
            "size": "icon",
            "forIcon": {
              color: 'yellow'
            },
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Pencil');
            },
            "tooltip": {
              show: true,
              text: 'Edit'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.toLeaveTypeForm(entry.id);
            }
          }
        }), _c('Button', {
          attrs: {
            "type": "delete",
            "size": "icon",
            "icon": function icon() {
              return import(
              /* webpackChunkName: 'icon' */
              '@/components/Icons/Trash');
            },
            "tooltip": {
              show: true,
              text: 'Delete'
            }
          },
          on: {
            "action": function action($event) {
              return _vm.showConfirmDelete(entry);
            }
          }
        })], 1) : column.label === 'Leave Limit' ? _c('div', [_vm._v(_vm._s(entry[column.field] === parseInt(0) ? 'Unlimited' : entry[column.field]))]) : _c('div', [_vm._v(_vm._s(entry[column.field]))])];
      }
    }], null, false, 4141356612)
  }), _vm.totalLeaveType > _vm.sizePerPage ? _c('div', {
    staticClass: "mt-3 flex justify-center items-center"
  }, [_c('Pagination', {
    attrs: {
      "currentPage": _vm.currentPage,
      "totalCount": _vm.totalLeaveType,
      "pageSize": _vm.sizePerPage
    },
    on: {
      "onPageChange": _vm.onPageChange
    }
  })], 1) : _vm._e()], 1) : _c('div', {
    staticClass: "text-center mt-10"
  }, [_c('img', {
    staticClass: "mx-auto",
    attrs: {
      "src": require("@/assets/images/empty-content.svg"),
      "alt": "empty"
    }
  }), _c('div', {
    staticClass: "font-medium text-xl py-5"
  }, [_vm._v("Data is not available")])])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.confirmDeleteAlertVisible,
      "width": "600px"
    },
    on: {
      "close": function close($event) {
        return _vm.toggleConfirmDelete();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "text-center my-2"
  }, [_c('h1', {
    staticClass: "text-xl font-bold mb-3"
  }, [_vm._v("Pemanently delete this Leave Type?")]), _c('p', {
    staticClass: "text-sm mb-4"
  }, [_vm._v("This action will delete " + _vm._s((_vm$leaveTypeDeleted = _vm.leaveTypeDeleted) === null || _vm$leaveTypeDeleted === void 0 ? void 0 : _vm$leaveTypeDeleted.name) + " Leave Type from Leave Management. This cannot be undone.")]), _c('div', {
    staticClass: "flex justify-center items-center gap-8"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Delete",
      "additionalClass": "px-20",
      "type": "secondary"
    },
    on: {
      "action": function action($event) {
        return _vm.deleteLeaveType();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Cancel",
      "additionalClass": "px-20",
      "type": "primary"
    },
    on: {
      "action": function action($event) {
        return _vm.toggleConfirmDelete();
      }
    }
  })], 1)])])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }